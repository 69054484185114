import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { EquipmentReportParameterRequest } from '@api/equipment/requests/equipment-report-parameter.request';

@jsonObject
export class EquipmentReportUpdateRequest {
  @jsonMember name!: string;
  @jsonArrayMember(EquipmentReportParameterRequest, {
    name: 'parameterValues',
  })
  parameters!: EquipmentReportParameterRequest[];

  constructor(name: string, parameters: EquipmentReportParameterRequest[]) {
    this.name = name;
    this.parameters = parameters;
  }
}
