import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiHelperService } from '@api/common/services/api-helper.service';
import { ApiUrlFactory } from '@api/common/factories/api-url.factory';
import { CollectionRequest } from '@api/common/requests/collection.request';
import { map, Observable } from 'rxjs';
import { CollectionResponse } from '@api/common/responses/collection.response';
import { Id } from '@shared/types/id';
import { ApiParams } from '@environments/enums/api-params';
import { CreateResourceResponse } from '@api/common/responses/create-recource.response';
import { environment } from '@environments/environment';
import { EquipmentReportUpdateRequest } from './requests/equipment-report-update.request';
import { EquipmentReportCreateRequest } from '@api/equipment/requests/equipment-report-create.request';
import { EquipmentReportCollectionItemResponse } from '@api/equipment/responses/equipment-report-collection-item.response';
import { EquipmentReportFilterCollectionRequest } from '@api/equipment/filters/equipment-report-filter-collection.request';
import { EquipmentReportDetailsResponse } from '@api/equipment/responses/equipment-report-details.response';

const { EQUIPMENT } = environment.api;

@Injectable({
  providedIn: 'root',
})
export class EquipmentReportApiService {
  constructor(
    private readonly http: HttpClient,
    private readonly helper: ApiHelperService,
    private readonly urlFactory: ApiUrlFactory
  ) {}

  collection(
    equipmentId: Id,
    request: CollectionRequest<EquipmentReportFilterCollectionRequest>
  ): Observable<CollectionResponse<EquipmentReportCollectionItemResponse>> {
    const {
      REPORTS: { LIST },
      HOST,
    } = EQUIPMENT;
    const url = this.urlFactory.complex(builder =>
      builder
        .host(HOST)
        .path(LIST)
        .params({
          [ApiParams.EquipmentId]: equipmentId,
        })
        .queryParams(
          this.helper.collectionRequestToQueryParams({
            request,
            filterRequestType: EquipmentReportFilterCollectionRequest,
          })
        )
        .build()
    );

    return this.http
      .get(url)
      .pipe(
        map(
          this.helper.bodyToCollectionResponse(
            EquipmentReportCollectionItemResponse
          )
        )
      );
  }

  details(equipmentId: Id, id: Id): Observable<EquipmentReportDetailsResponse> {
    const {
      REPORTS: { DETAILS },
      HOST,
    } = EQUIPMENT;
    const url = this.urlFactory.withParams(HOST, DETAILS, {
      [ApiParams.EquipmentId]: String(equipmentId),
      [ApiParams.ReportId]: String(id),
    });

    return this.http
      .get(url)
      .pipe(map(this.helper.bodyToResponse(EquipmentReportDetailsResponse)));
  }

  create(
    equipmentId: Id,
    request: EquipmentReportCreateRequest
  ): Observable<CreateResourceResponse> {
    const {
      REPORTS: { CREATE },
      HOST,
    } = EQUIPMENT;
    const url = this.urlFactory.withParams(HOST, CREATE, {
      [ApiParams.EquipmentId]: equipmentId,
    });
    const body = this.helper.requestToBody(
      request,
      EquipmentReportCreateRequest
    );

    return this.http
      .post(url, body)
      .pipe(map(this.helper.bodyToResponse(CreateResourceResponse)));
  }

  update(
    equipmentId: Id,
    id: Id,
    request: EquipmentReportUpdateRequest
  ): Observable<void> {
    const {
      REPORTS: { UPDATE },
      HOST,
    } = EQUIPMENT;
    const url = this.urlFactory.withParams(HOST, UPDATE, {
      [ApiParams.ReportId]: String(id),
      [ApiParams.EquipmentId]: equipmentId,
    });
    const body = this.helper.requestToBody(
      request,
      EquipmentReportUpdateRequest
    );

    return this.http.put<void>(url, body);
  }

  remove(equipmentId: Id, id: Id): Observable<void> {
    const {
      REPORTS: { REMOVE },
      HOST,
    } = EQUIPMENT;
    const url = this.urlFactory.withParams(HOST, REMOVE, {
      [ApiParams.ReportId]: String(id),
      [ApiParams.EquipmentId]: equipmentId,
    });

    return this.http.delete<void>(url);
  }
}
