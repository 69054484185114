import { jsonMember, jsonObject } from 'typedjson';
import { Id } from '@shared/types/id';
import { CreatedByResponse } from '@api/user/responses/created-by.response';

@jsonObject
export class EquipmentReportCollectionItemResponse {
  @jsonMember readonly id!: Id;
  @jsonMember readonly name!: string;
  @jsonMember readonly createdAt!: Date;
  @jsonMember readonly createdBy!: CreatedByResponse;
}
