import { Injectable } from '@angular/core';
import { EquipmentReportDetailsResponse } from '@api/equipment/responses/equipment-report-details.response';
import { EquipmentReport } from '@features/equipment/models/equipment-report';
import { CreatedBy } from '@features/user/models/created-by';
import { EquipmentReportParameter } from '@features/equipment/models/equipment-report-parameter';

@Injectable({
  providedIn: 'root',
})
export class EquipmentReportDetailsConverter {
  fromResponse(response: EquipmentReportDetailsResponse): EquipmentReport {
    return new EquipmentReport(
      response.id,
      response.name,
      response.createdAt,
      new CreatedBy(
        response.createdBy.id,
        response.createdBy.email,
        response.createdBy.identifier
      ),
      this.prepareProperties(response)
    );
  }

  private prepareProperties(
    response: EquipmentReportDetailsResponse
  ): EquipmentReportParameter[] {
    const { parameters } = response;

    return parameters.map(
      parameter =>
        new EquipmentReportParameter(
          parameter.id,
          parameter.name,
          parameter.value
        )
    );
  }
}
