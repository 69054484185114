import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { Id } from '@shared/types/id';
import { CreatedByResponse } from '@api/user/responses/created-by.response';
import { EquipmentReportParameterResponse } from '@api/equipment/responses/equipment-report-parameter.response';

@jsonObject
export class EquipmentReportDetailsResponse {
  @jsonMember readonly id!: Id;
  @jsonMember readonly name!: string;
  @jsonMember readonly createdAt!: Date;
  @jsonMember readonly createdBy!: CreatedByResponse;
  @jsonArrayMember(EquipmentReportParameterResponse, {
    name: 'parameterValues',
  })
  parameters!: EquipmentReportParameterResponse[];
}
