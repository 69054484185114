import { Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { Id } from '@shared/types/id';
import { CreateResourceResponse } from '@api/common/responses/create-recource.response';
import { ToastService } from '@shared/toast/services/toast.service';
import { ConfirmationService } from '@shared/confirmation/services/confirmation.service';
import { EquipmentReportUpsertFormData } from '@features/equipment/components/equipment-report-upsert-form/models/equipment-report-upsert-form-data';
import { EquipmentReportCreateRequest } from '@api/equipment/requests/equipment-report-create.request';
import { EquipmentReportUpdateRequest } from '@api/equipment/requests/equipment-report-update.request';
import { EquipmentReportApiService } from '@api/equipment/equipment-report-api.service';
import { EquipmentReportParameterRequest } from '@api/equipment/requests/equipment-report-parameter.request';
import { EquipmentReport } from '@features/equipment/models/equipment-report';
import { EquipmentReportDetailsConverter } from '@features/equipment/converters/equipment-report-details.converter';

@Injectable({
  providedIn: 'root',
})
export class EquipmentReportActionService {
  constructor(
    private readonly equipmentReportApi: EquipmentReportApiService,
    private readonly toast: ToastService,
    private readonly confirmation: ConfirmationService,
    private readonly detailsConverter: EquipmentReportDetailsConverter
  ) {}

  fetch(equipmentId: Id, id: Id): Observable<EquipmentReport> {
    return this.equipmentReportApi
      .details(equipmentId, id)
      .pipe(map(response => this.detailsConverter.fromResponse(response)));
  }

  create(
    equipmentId: Id,
    data: EquipmentReportUpsertFormData
  ): Observable<CreateResourceResponse> {
    const parameters = data.parameters.map(
      parameter =>
        new EquipmentReportParameterRequest(parameter.id, parameter.value)
    );
    const request = new EquipmentReportCreateRequest(data.name, parameters);

    return this.equipmentReportApi.create(equipmentId, request);
  }

  update(
    equipmentId: Id,
    id: Id,
    data: EquipmentReportUpsertFormData
  ): Observable<void> {
    const parameters = data.parameters.map(
      parameter =>
        new EquipmentReportParameterRequest(parameter.id, parameter.value)
    );
    const request = new EquipmentReportUpdateRequest(data.name, parameters);

    return this.equipmentReportApi.update(equipmentId, id, request);
  }

  remove(equipmentId: Id, id: Id): Observable<void> {
    return this.equipmentReportApi.remove(equipmentId, id).pipe(
      tap({
        next: () =>
          this.toast.show(builder =>
            builder.initSuccess('Raport został usunięty pomyślnie.').build()
          ),
        error: () =>
          this.toast.show(builder =>
            builder.initError('Nie udało się usunąć raportu.').build()
          ),
      })
    );
  }

  createByForm(
    equipmentId: Id,
    data: EquipmentReportUpsertFormData
  ): Observable<CreateResourceResponse> {
    return this.create(equipmentId, data).pipe(
      tap({
        next: () =>
          this.toast.show(builder =>
            builder.initSuccess('Raport został utworzony pomyślnie.').build()
          ),
        error: () =>
          this.toast.show(builder =>
            builder.initError('Nie udało się utworzyć raportu.').build()
          ),
      })
    );
  }

  updateByForm(
    equipmentId: Id,
    id: Id,
    data: EquipmentReportUpsertFormData
  ): Observable<void> {
    return this.update(equipmentId, id, data).pipe(
      tap({
        next: () =>
          this.toast.show(builder =>
            builder
              .initSuccess('Raport został zaktualizowany pomyślnie.')
              .build()
          ),
        error: () =>
          this.toast.show(builder =>
            builder.initError('Nie udało się zaktualizować raportu.').build()
          ),
      })
    );
  }

  confirmAndRemove(equipmentId: Id, id: Id): Observable<void> {
    return new Observable<void>(subscriber => {
      this.confirmation.show(builder =>
        builder
          .title('Potwierdzenie')
          .message('Czy na pewno chcesz usunąć wybrany raport?')
          .accept(() => this.remove(equipmentId, id).subscribe(subscriber))
          .reject(() => subscriber.complete())
          .build()
      );
    });
  }
}
