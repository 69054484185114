import { jsonMember, jsonObject } from 'typedjson';
import { Id } from '@shared/types/id';

@jsonObject
export class EquipmentReportParameterRequest {
  @jsonMember({ name: 'parameterId' }) id!: Id;
  @jsonMember value!: string;

  constructor(id: Id, value: string) {
    this.id = id;
    this.value = value;
  }
}
