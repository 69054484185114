import { Id } from '@shared/types/id';
import { CreatedBy } from '@features/user/models/created-by';
import { EquipmentReportParameter } from '@features/equipment/models/equipment-report-parameter';

export class EquipmentReport {
  constructor(
    readonly id: Id,
    readonly name: string,
    readonly createdAt: Date,
    readonly createdBy: CreatedBy,
    readonly properties: EquipmentReportParameter[]
  ) {}
}
