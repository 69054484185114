import { jsonMember, jsonObject } from 'typedjson';
import { dateSerializer } from '@api/common/json/date-serializer';

@jsonObject
export class EquipmentReportFilterCollectionRequest {
  @jsonMember name?: string;
  @jsonMember({ name: 'createdByIdentifier' }) identifier?: string;
  @jsonMember({ serializer: dateSerializer() }) createdAtFrom?: Date;
  @jsonMember({ serializer: dateSerializer() }) createdAtTo?: Date;
}
